import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { prepareAuthHeaders } from 'helpers/api';

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.SYS_ACC_API,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: ['groupList', 'userByGroupList'],
  endpoints: (builder) => ({
    fetchGroup: builder.query({
      query: () => ({
        url: '/account/group',
      }),
      providesTags: ['groupList'],
    }),

    fetchUserByGroup: builder.query({
      query: (groupId) => ({
        url: `/admin/account/group/${groupId}`,
      }),
      providesTags: ['userByGroupList'],
    }),
    createUser: builder.mutation({
      query: (payload) => ({
        url: '/admin/account/create',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['userByGroupList'],
    }),
    updateUser: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/admin/account/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['userByGroupList'],
    }),
  }),
});

export const { useFetchGroupQuery, useFetchUserByGroupQuery, useCreateUserMutation, useUpdateUserMutation } = adminApi;
